import {
  PDF as SharedLibraryPDF,
  Template,
  PDFModifier,
  Notification,
  pdfDownloadToast,
} from 'shared-library';
import React, { useContext, useEffect, useState } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';

import { fetchAppsResource, fetchPiiResource } from 'packages/apis';
import { ReportIssueButton } from 'packages/report-issue-button';
import styled from 'styled-components';
import NavbarContainer from '../Navbar';
import { RootContext } from '../RootContext';

const PDFContainer = styled.div`
  width: 964px;
  margin: auto;
`;

const PDF = () => {
  const { rootElement } = useContext(RootContext);
  const pdfTitle = rootElement.getAttribute('data-pdfTitle');
  const pdfNameURL = rootElement.getAttribute('data-pdfNameURL');
  const studyId = rootElement.getAttribute('data-studyID');
  const prenuvoID = rootElement.getAttribute('data-prenuvoID');
  const url = rootElement.getAttribute('data-url');
  const reportType = rootElement.getAttribute('data-reportType');
  const templateName = reportType === 'structured' ? 'structured' : Template.traditional;
  const userFields =
    document.getElementById('legacy-details-json') &&
    JSON.parse(document.getElementById('legacy-details-json').textContent);

  const { addToast } = useToasts();

  const [pdf, setPDF] = useState<string>('');
  const [fetchLoading, setFetchLoading] = useState(false);

  const getPDFModifier = async () => {
    if (!prenuvoID) {
      return new PDFModifier({});
    }
    const { parsedBody } = await fetchPiiResource(`/api/v1/userV2/${prenuvoID}`);
    const user = {
      ...parsedBody,
      fakeFirstname: parsedBody.fake_firstname,
      fakeLastname: parsedBody.fake_lastname,
      fakeDob: parsedBody.fake_dob,
    };
    return new PDFModifier({
      user,
      studyId,
      templateName,
      fields: new Map(Object.entries(userFields)),
    });
  };

  const fetchPDF = async () => {
    try {
      setFetchLoading(true);
      const pdfModifier = await getPDFModifier();

      const pdfBase64 = await pdfModifier.fetchPdf(url);

      if (pdfBase64) {
        setPDF(pdfBase64);
      }
    } catch (e) {
      throw new Error(e.message || 'Something went wrong');
    } finally {
      setFetchLoading(false);
    }
  };

  const displayDownloadToast = () => {
    const { title, body } = pdfDownloadToast();
    addToast(Notification.create(title, body), { appearance: 'success' });
  }

  const download = async () => {
    displayDownloadToast();
    if (pdfNameURL){
      const { parsedBody: pdfNameWithoutFilename } = await fetchAppsResource(pdfNameURL);
      const pdfName = pdfNameWithoutFilename ? `${pdfNameWithoutFilename}.pdf` : '';
      const pdfModifier = await getPDFModifier();
      pdfModifier.download(pdf, pdfName);
    } else {
      const pdfModifier = await getPDFModifier();
      pdfModifier.download(pdf);
    }
  };

  useEffect(() => {
    fetchPDF();
  }, []);

  if (!pdf) return null;

  return (
    <>
      <NavbarContainer />
      <PDFContainer>
        <SharedLibraryPDF
          title={pdfTitle || 'Report PDF'}
          pdf={pdf}
          onDownload={download}
          renderTextLayer={false}
          showPageButtons={false}
        />
      </PDFContainer>
    </>
  );
};

const PDFWithToastProvider = () => (
    <ToastProvider autoDismiss components={{ Toast: Notification }} placement="bottom-right">
      <PDF />
      <ReportIssueButton />
    </ToastProvider>
);

export default PDFWithToastProvider;
